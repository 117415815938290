
import Vue from 'vue'
export default Vue.extend({
  name: 'AdditionalCommands',
  inject: ['spaParent'],
  props: {
    cameras: Array as () => Camera[],
  },
  data() {
    return {
      showCommands: true,
      unmount: [] as any,
    }
  },
  computed: {
    cameraIds(): string[] {
      return this.cameras.map(({ id }) => id)
    }
  },
  watch: {
    cameras() {
      this.showCommands = false
      setTimeout(() => {
        this.showCommands = true
        setTimeout(() => {
          this.mountWidgets()
        })
      })
    },
  },
  destroyed() {
    this.unmount.forEach((e: any) => e())
  },
  mounted() {
    this.mountWidgets()
  },
  methods: {
    mountWidget(placeholder: HTMLElement, areas: any[]) {
      // @ts-ignore
      const spaParent = this.spaParent as SpaParent
      for (const area of areas) {
        this.unmount = [
          ...this.unmount,
          spaParent.mountChildren(
            placeholder,
            area.children.map((e: any) => {
              return {
                ...e,
                props: {
                  ...e.props,
                  cameraIds: this.cameraIds,
                },
              }
            }),
          ),
        ]
      }
    },
    mountWidgets() {
      const commandPlaceholder = this.$refs.commands as HTMLElement

      // @ts-ignore
      const commands = this.spaParent.areas.filter((e: any) => e.name === 'additionalCommands')

      this.mountWidget(commandPlaceholder, commands)
    },
  },
})
