var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"width":"100%","height":"calc(100vh - 152px)"}},[(_vm.camerasLayout !== null)?_c('CardsView',{attrs:{"loading":_vm.mainLoading},scopedSlots:_vm._u([{key:"header",fn:function(){return [(!_vm.editMode)?_c('Button',{staticClass:"p-button-secondary p-mr-2",attrs:{"label":_vm.$t('button.edit'),"data-cy":"button-edit"},on:{"click":function($event){return _vm.$emit('update:editMode', true)}}}):_vm._e(),(_vm.editMode)?_c('Button',{staticClass:"p-mr-2",attrs:{"label":_vm.$t('button.close'),"data-cy":"button-close","icon":"mdi mdi-18px mdi-close"},on:{"click":function($event){return _vm.$emit('update:editMode', false)}}}):_vm._e(),(_vm.editMode)?_c('LayoutStyleSelectSet',{attrs:{"currentLayoutType":_vm.currentLayoutType,"totalCount":_vm.camerasLayout.cameras.filter((e) => e !== '').length},on:{"update:currentLayoutType":function($event){_vm.currentLayoutType=$event},"update:current-layout-type":function($event){_vm.currentLayoutType=$event}}}):_vm._e(),_c('div',{staticClass:"p-ml-2"},[(_vm.isAdditionalCommandsProvided && !_vm.editMode)?_c('AdditionalCommands',{attrs:{"cameras":_vm.layouts[_vm.camerasLayout.id].cameras.filter(({ id }) =>
              _vm.camerasLayout.cameras.includes(id),
            )}}):_vm._e()],1),_c('div',{staticStyle:{"flex-grow":"1"}}),(_vm.editMode)?_c('Button',{staticClass:"p-button-danger p-button-outlined",attrs:{"label":_vm.$t('button.deleteLayout'),"data-cy":"button-delete-layout-edit"},on:{"click":function($event){_vm.displayDeleteLayout = true}}}):_vm._e()]},proxy:true}],null,false,149981116)},[_c('div',{staticStyle:{"width":"100%","height":"100%","overflow":"hidden"}},[_c('div',{staticClass:"cameras-layout-container"},[_c('AddCameraSidebar',{ref:"AddCameraSidebar",attrs:{"expanded":_vm.editMode,"camerasLayout":_vm.camerasLayout},on:{"addCamera":_vm.addCamera}}),_c('div',{staticClass:"layout",class:[
            _vm.LAYOUT_TYPES[_vm.camerasLayout.layoutType].class
              ? _vm.LAYOUT_TYPES[_vm.camerasLayout.layoutType].class
              : '',
            _vm.isFullscreen ? 'full-screen-grid' : '',
            _vm.LAYOUT_TYPES[_vm.camerasLayout.layoutType].gridClass,
          ],attrs:{"id":"fullScreenAnchor","data-cy":"cameras-grid"}},_vm._l((_vm.camerasLayout.cameras),function(camera,index){return _c('div',{key:`${camera}${index}`,staticClass:"camera-wrapper draggable sortable",class:{ 'camera-wrapper__empty': camera === '' },attrs:{"id":`${camera}${index}`,"data-cy":"camera-in-editor","data-id":index},on:{"mousemove":_vm.setButtonVisible}},[(
                !_vm.mainLoading &&
                camera !== '' &&
                camera !== null &&
                _vm.layouts[_vm.camerasLayout.id] &&
                _vm.layouts[_vm.camerasLayout.id].cameras.length
              )?_c('CameraCard',{attrs:{"camera":_vm.layouts[_vm.camerasLayout.id].cameras.find(
                  ({ id, indexInLayout }) => camera === id && indexInLayout === index,
                ),"layoutStreamTypes":_vm.layoutStreamTypes,"isFullscreen":_vm.isFullscreen,"editMode":_vm.editMode},on:{"toggleFullscreen":_vm.toggleFullscreen,"changeStream":function($event){return _vm.setCameraStream([_vm.camerasLayout.id, camera, $event])},"changeLayoutStreamType":function($event){return _vm.setLayoutStream([_vm.camerasLayout.id, $event])}}}):_c('div',{staticClass:"empty-camera"},[_c('i',{staticClass:"mdi mdi-video empty-camera-icon"}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('message.cameraNotChosen'))}})]),(_vm.editMode)?_c('div',{staticClass:"camera-header p-px-2"},[(
                  camera !== '' &&
                  _vm.canI('UpdateCamerasLayout', _vm.camerasLayout.id) &&
                  _vm.camerasLayout.cameras.length > 1
                )?_c('i',{staticClass:"mdi mdi-24px mdi-drag sortable-handle"}):_vm._e(),(camera !== '' && _vm.canI('UpdateCamerasLayout', _vm.camerasLayout.id))?_c('i',{staticClass:"mdi mdi-24px mdi-close delete-camera",attrs:{"data-cy":"delete-button-on-camera"},on:{"click":function($event){_vm.deleteCameraIndex = index
                  _vm.displayDeleteCamera = true
                  _vm.deleteCameraId = camera}}}):_vm._e()]):_vm._e()],1)}),0)],1)])]):_vm._e(),_c('DeleteDialog',{attrs:{"show":_vm.displayDeleteLayout,"message":_vm.$t('message.deleteLayout'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteLayout=$event},"deleteEntity":_vm.deleteLayout}}),_c('DeleteDialog',{attrs:{"show":_vm.displayDeleteCamera,"message":_vm.$t('message.deleteCamera'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteCamera=$event},"deleteEntity":_vm.deleteCamera}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }